@import url("https://fonts.googleapis.com/css?family=Raleway:200,400,700");
/*-------------------------------------*\
	HINT.css - A CSS tooltip library
\*-------------------------------------*/
/**
 * HINT.css is a tooltip library made in pure CSS.
 *
 * Source: https://github.com/chinchang/hint.css
 * Demo: http://kushagragour.in/lab/hint/
 *
 */
/**
 * source: hint-core.scss
 *
 * Defines the basic styling for the tooltip.
 * Each tooltip is made of 2 parts:
 * 	1) body (:after)
 * 	2) arrow (:before)
 *
 * Classes added:
 * 	1) hint
 */
[class*="hint--"] {
  position: relative;
  display: inline-block;
  /**
	 * tooltip arrow
	 */
  /**
	 * tooltip body
	 */ }
  [class*="hint--"]:before, [class*="hint--"]:after {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-transition-delay: 0ms;
    -moz-transition-delay: 0ms;
    transition-delay: 0ms; }
  [class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
    visibility: visible;
    opacity: 1; }
  [class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms; }
  [class*="hint--"]:before {
    content: '';
    position: absolute;
    background: transparent;
    border: 6px solid transparent;
    z-index: 1000001; }
  [class*="hint--"]:after {
    background: #383838;
    color: white;
    padding: 8px 10px;
    font-size: 12px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 12px;
    white-space: nowrap; }
  [class*="hint--"][aria-label]:after {
    content: attr(aria-label); }
  [class*="hint--"][data-hint]:after {
    content: attr(data-hint); }

[aria-label='']:before, [aria-label='']:after,
[data-hint='']:before,
[data-hint='']:after {
  display: none !important; }

/**
 * source: hint-position.scss
 *
 * Defines the positoning logic for the tooltips.
 *
 * Classes added:
 * 	1) hint--top
 * 	2) hint--bottom
 * 	3) hint--left
 * 	4) hint--right
 */
/**
 * set default color for tooltip arrows
 */
.hint--top-left:before {
  border-top-color: #383838; }

.hint--top-right:before {
  border-top-color: #383838; }

.hint--top:before {
  border-top-color: #383838; }

.hint--bottom-left:before {
  border-bottom-color: #383838; }

.hint--bottom-right:before {
  border-bottom-color: #383838; }

.hint--bottom:before {
  border-bottom-color: #383838; }

.hint--left:before {
  border-left-color: #383838; }

.hint--right:before {
  border-right-color: #383838; }

/**
 * top tooltip
 */
.hint--top:before {
  margin-bottom: -11px; }

.hint--top:before, .hint--top:after {
  bottom: 100%;
  left: 50%; }

.hint--top:before {
  left: calc(50% - 6px); }

.hint--top:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

.hint--top:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--top:hover:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

/**
 * bottom tooltip
 */
.hint--bottom:before {
  margin-top: -11px; }

.hint--bottom:before, .hint--bottom:after {
  top: 100%;
  left: 50%; }

.hint--bottom:before {
  left: calc(50% - 6px); }

.hint--bottom:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

.hint--bottom:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--bottom:hover:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

/**
 * right tooltip
 */
.hint--right:before {
  margin-left: -11px;
  margin-bottom: -6px; }

.hint--right:after {
  margin-bottom: -14px; }

.hint--right:before, .hint--right:after {
  left: 100%;
  bottom: 50%; }

.hint--right:hover:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

.hint--right:hover:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/**
 * left tooltip
 */
.hint--left:before {
  margin-right: -11px;
  margin-bottom: -6px; }

.hint--left:after {
  margin-bottom: -14px; }

.hint--left:before, .hint--left:after {
  right: 100%;
  bottom: 50%; }

.hint--left:hover:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--left:hover:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

/**
 * top-left tooltip
 */
.hint--top-left:before {
  margin-bottom: -11px; }

.hint--top-left:before, .hint--top-left:after {
  bottom: 100%;
  left: 50%; }

.hint--top-left:before {
  left: calc(50% - 6px); }

.hint--top-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

.hint--top-left:after {
  margin-left: 12px; }

.hint--top-left:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--top-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

/**
 * top-right tooltip
 */
.hint--top-right:before {
  margin-bottom: -11px; }

.hint--top-right:before, .hint--top-right:after {
  bottom: 100%;
  left: 50%; }

.hint--top-right:before {
  left: calc(50% - 6px); }

.hint--top-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.hint--top-right:after {
  margin-left: -12px; }

.hint--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--top-right:hover:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

/**
 * bottom-left tooltip
 */
.hint--bottom-left:before {
  margin-top: -11px; }

.hint--bottom-left:before, .hint--bottom-left:after {
  top: 100%;
  left: 50%; }

.hint--bottom-left:before {
  left: calc(50% - 6px); }

.hint--bottom-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

.hint--bottom-left:after {
  margin-left: 12px; }

.hint--bottom-left:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--bottom-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

/**
 * bottom-right tooltip
 */
.hint--bottom-right:before {
  margin-top: -11px; }

.hint--bottom-right:before, .hint--bottom-right:after {
  top: 100%;
  left: 50%; }

.hint--bottom-right:before {
  left: calc(50% - 6px); }

.hint--bottom-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.hint--bottom-right:after {
  margin-left: -12px; }

.hint--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--bottom-right:hover:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

/**
 * source: hint-sizes.scss
 *
 * Defines width restricted tooltips that can span
 * across multiple lines.
 *
 * Classes added:
 * 	1) hint--small
 * 	2) hint--medium
 * 	3) hint--large
 *
 */
.hint--small:after,
.hint--medium:after,
.hint--large:after {
  white-space: normal;
  line-height: 1.4em;
  word-wrap: break-word; }

.hint--small:after {
  width: 80px; }

.hint--medium:after {
  width: 150px; }

.hint--large:after {
  width: 300px; }

/**
 * source: hint-theme.scss
 *
 * Defines basic theme for tooltips.
 *
 */
[class*="hint--"] {
  /**
	 * tooltip body
	 */ }
  [class*="hint--"]:after {
    text-shadow: 0 -1px 0px black;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); }

/**
 * source: hint-color-types.scss
 *
 * Contains tooltips of various types based on color differences.
 *
 * Classes added:
 * 	1) hint--error
 * 	2) hint--warning
 * 	3) hint--info
 * 	4) hint--success
 *
 */
/**
 * Error
 */
.hint--error:after {
  background-color: #b34e4d;
  text-shadow: 0 -1px 0px #592726; }

.hint--error.hint--top-left:before {
  border-top-color: #b34e4d; }

.hint--error.hint--top-right:before {
  border-top-color: #b34e4d; }

.hint--error.hint--top:before {
  border-top-color: #b34e4d; }

.hint--error.hint--bottom-left:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--bottom-right:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--left:before {
  border-left-color: #b34e4d; }

.hint--error.hint--right:before {
  border-right-color: #b34e4d; }

/**
 * Warning
 */
.hint--warning:after {
  background-color: #c09854;
  text-shadow: 0 -1px 0px #6c5328; }

.hint--warning.hint--top-left:before {
  border-top-color: #c09854; }

.hint--warning.hint--top-right:before {
  border-top-color: #c09854; }

.hint--warning.hint--top:before {
  border-top-color: #c09854; }

.hint--warning.hint--bottom-left:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--bottom-right:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--left:before {
  border-left-color: #c09854; }

.hint--warning.hint--right:before {
  border-right-color: #c09854; }

/**
 * Info
 */
.hint--info:after {
  background-color: #3986ac;
  text-shadow: 0 -1px 0px #1a3c4d; }

.hint--info.hint--top-left:before {
  border-top-color: #3986ac; }

.hint--info.hint--top-right:before {
  border-top-color: #3986ac; }

.hint--info.hint--top:before {
  border-top-color: #3986ac; }

.hint--info.hint--bottom-left:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--bottom-right:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--bottom:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--left:before {
  border-left-color: #3986ac; }

.hint--info.hint--right:before {
  border-right-color: #3986ac; }

/**
 * Success
 */
.hint--success:after {
  background-color: #458746;
  text-shadow: 0 -1px 0px #1a321a; }

.hint--success.hint--top-left:before {
  border-top-color: #458746; }

.hint--success.hint--top-right:before {
  border-top-color: #458746; }

.hint--success.hint--top:before {
  border-top-color: #458746; }

.hint--success.hint--bottom-left:before {
  border-bottom-color: #458746; }

.hint--success.hint--bottom-right:before {
  border-bottom-color: #458746; }

.hint--success.hint--bottom:before {
  border-bottom-color: #458746; }

.hint--success.hint--left:before {
  border-left-color: #458746; }

.hint--success.hint--right:before {
  border-right-color: #458746; }

/**
 * source: hint-always.scss
 *
 * Defines a persisted tooltip which shows always.
 *
 * Classes added:
 * 	1) hint--always
 *
 */
.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible; }

.hint--always.hint--top:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--always.hint--top:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

.hint--always.hint--top-left:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--always.hint--top-left:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

.hint--always.hint--top-right:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--always.hint--top-right:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--always.hint--bottom:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--always.hint--bottom:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

.hint--always.hint--bottom-left:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--always.hint--bottom-left:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

.hint--always.hint--bottom-right:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--always.hint--bottom-right:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--always.hint--left:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--always.hint--left:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--always.hint--right:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

.hint--always.hint--right:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

/**
 * source: hint-rounded.scss
 *
 * Defines rounded corner tooltips.
 *
 * Classes added:
 * 	1) hint--rounded
 *
 */
.hint--rounded:after {
  border-radius: 4px; }

/**
 * source: hint-effects.scss
 *
 * Defines various transition effects for the tooltips.
 *
 * Classes added:
 * 	1) hint--no-animate
 * 	2) hint--bounce
 *
 */
.hint--no-animate:before, .hint--no-animate:after {
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms; }

.hint--bounce:before, .hint--bounce:after {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif; }

body {
  background-color: whitesmoke; }

.page-container {
  width: 100%;
  border-top: 10px solid black; }
  .page-container a {
    text-decoration: none;
    color: #2b81be; }
  .page-container nav {
    position: fixed;
    top: -65px;
    width: 100%;
    height: 65px;
    padding-left: 15px;
    background-color: black;
    padding-top: 15px;
    overflow: hidden;
    font-weight: 200;
    letter-spacing: 5px;
    text-transform: uppercase;
    z-index: 5000; }
    .page-container nav img {
      height: 40px;
      float: left; }
    .page-container nav .chapter {
      color: #e8e7f0;
      font-size: 1em;
      margin-left: 20px;
      padding-left: 20px;
      line-height: 2em;
      border-left: 1px solid grey; }
    .page-container nav .email {
      font-size: 1.8rem;
      color: #e8e7f0;
      float: right;
      margin-right: 15px; }
  .page-container header {
    width: 100%;
    position: relative; }
    .page-container header .email {
      position: absolute;
      top: 20px;
      left: 20px;
      background-color: black;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer; }
      .page-container header .email a {
        color: #e8e7f0; }
    .page-container header .title {
      padding: 2rem;
      width: 100%; }
      .page-container header .title h1 {
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        margin-bottom: 10px;
        font-size: 1.6rem; }
      .page-container header .title p {
        line-height: 1.5rem; }
    .page-container header .signature {
      position: absolute;
      right: 27vw;
      top: 8rem;
      width: 68vw;
      z-index: 10;
      display: none; }
    .page-container header .arrow {
      display: none; }
  .page-container footer {
    background-color: black;
    color: #e8e7f0;
    padding: 20px;
    text-align: center;
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: .9rem; }
    .page-container footer .copyright {
      display: none; }
    .page-container footer .email {
      text-align: left; }
      .page-container footer .email a {
        color: #e8e7f0; }
    .page-container footer .badges {
      text-align: right; }
      .page-container footer .badges .fab {
        font-size: 1.1rem;
        display: inline;
        margin-left: 15px;
        cursor: pointer;
        color: #e8e7f0;
        background: none; }

section {
  padding: 50px 40px;
  margin: 0px auto 0px;
  width: 100%;
  max-width: 900px; }
  section .location {
    text-align: center;
    margin: 20px 0px 10px;
    font-weight: 400; }
  section div.entry {
    margin-top: 20px; }
  section .header {
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 200;
    letter-spacing: 5px;
    font-size: 20px;
    margin-bottom: 50px; }
  section .time {
    font-weight: 200;
    display: block;
    margin: 5px 0px 20px; }
  section img {
    width: 100%; }

section.quotes {
  background: black;
  max-width: none;
  color: #e8e7f0; }
  section.quotes p {
    max-width: 800px;
    margin: 0px auto;
    font-size: 1rem;
    line-height: 1.5rem; }
    section.quotes p .fa-quote-left {
      margin-right: 20px; }
    section.quotes p .fa-quote-right {
      margin: 0px 20px; }
  section.quotes .attribution {
    width: 100%;
    text-align: right; }

section.portfolio article {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  margin-bottom: 70px; }
  section.portfolio article:last-child {
    margin-bottom: 0px; }
  section.portfolio article .description {
    width: 100%; }
    section.portfolio article .description .text {
      line-height: 25px; }
    section.portfolio article .description .title {
      display: block;
      margin: 20px 0px;
      text-align: center;
      width: 100%;
      font-size: 1.6rem;
      font-weight: 100; }
    section.portfolio article .description .links {
      margin-top: 30px;
      display: block; }
      section.portfolio article .description .links a {
        width: 150px;
        margin: 0px auto;
        border: 1px solid #2b81be;
        text-align: center;
        display: block;
        white-space: nowrap;
        font-weight: 700;
        margin-top: 15px;
        cursor: pointer;
        border-radius: 10px;
        padding: 10px; }
        section.portfolio article .description .links a:hover {
          background-color: #edf5fb; }
      section.portfolio article .description .links i {
        margin-right: 10px; }
    section.portfolio article .description .badges {
      font-size: 1.3rem;
      margin-top: 30px; }
      section.portfolio article .description .badges i {
        width: 25px;
        height: 25px;
        line-height: 25px;
        background-color: #2b81be;
        color: #e8e7f0;
        border-radius: 50%;
        text-align: center;
        font-size: 0.9rem;
        cursor: pointer; }

section.experience {
  margin: 0px auto; }
  section.experience .logo {
    width: 100%;
    text-align: center;
    margin: 30px 0px 40px; }
    section.experience .logo img {
      max-width: 15rem;
      width: 70%; }
  section.experience article {
    margin-bottom: 50px; }
    section.experience article p {
      line-height: 27px; }
    section.experience article .skills {
      margin-top: 20px; }

section.skills {
  background-color: #e8e7f0;
  color: black;
  padding-bottom: 30px;
  margin-bottom: 0px;
  max-width: none; }
  section.skills .logos {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    margin: 30px auto 0px;
    max-width: 800px; }
    section.skills .logos .container {
      width: 100%;
      text-align: center; }
      section.skills .logos .container .logo {
        display: inline-block;
        width: 55px;
        height: 55px;
        line-height: 38px;
        padding: 10px;
        font-size: 30px;
        background-color: none;
        border-radius: 50%;
        margin-bottom: 5px; }
      section.skills .logos .container .skill {
        display: block; }

section.development, section.learning {
  background-color: #e8e7f0;
  color: black;
  padding-bottom: 30px;
  margin-bottom: 0px;
  max-width: none; }
  section.development .logos, section.learning .logos {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    margin: 30px auto 0px;
    max-width: 800px; }
    section.development .logos .container, section.learning .logos .container {
      width: 100%;
      text-align: center; }
      section.development .logos .container .logo, section.learning .logos .container .logo {
        display: inline-block;
        width: 55px;
        height: 55px;
        line-height: 38px;
        padding: 10px;
        font-size: 30px;
        background-color: none;
        border-radius: 50%;
        margin-bottom: 5px; }
      section.development .logos .container .skill, section.learning .logos .container .skill {
        display: block; }

section.learning {
  padding-bottom: 50px; }

section.education {
  margin: 0px auto;
  max-width: 800px; }
  section.education .entry {
    margin-top: 30px; }
    section.education .entry .time {
      margin-bottom: 7px; }

section.awards {
  margin: 0px auto;
  max-width: 800px;
  padding-bottom: 0px; }
  section.awards article {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px; }
  section.awards .award-description {
    margin-bottom: 50px;
    max-width: 90vw;
    text-align: center;
    margin: 0px auto 50px; }
    section.awards .award-description a {
      font-size: inherit;
      font-weight: normal;
      margin: 0px;
      white-space: normal;
      display: inline; }
  section.awards .entry.thumb {
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-gap: 20px; }
  section.awards .entry.no-thumb {
    text-align: center; }
  section.awards .thumb-container {
    float: left;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0px 10px 10px 0px;
    overflow: hidden; }
    section.awards .thumb-container img {
      width: 100%; }
  section.awards .location {
    margin-top: 60px;
    margin-bottom: 5px; }
  section.awards .winner {
    font-style: italic;
    font-weight: 200; }
  section.awards a {
    display: block;
    white-space: nowrap;
    font-style: normal;
    font-weight: 700;
    font-size: 14px; }

@media (min-width: 400px) {
  .page-container header .title {
    padding-top: 70px; }
  .page-container header .signature {
    right: 33vw;
    bottom: 10rem;
    width: 62vw; } }

@media (min-width: 600px) {
  .page-container header .title {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    width: 100%;
    padding: 0px; }
  .page-container header .signature {
    right: 44vw;
    top: 2rem;
    width: 52vw; }
  .page-container footer {
    grid-template-columns: repeat(3, 1fr); }
    .page-container footer .copyright {
      display: inline; }
    .page-container footer .email {
      text-align: center; }
    .page-container footer .copyright {
      text-align: left; }
  section.awards .award-description {
    max-width: 60vw; }
  section.portfolio article {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px; }
    section.portfolio article .description .title {
      text-align: left;
      font-size: 1.5rem;
      width: 100%; }
    section.portfolio article .description .links a {
      display: inline-block;
      width: 100px;
      margin-right: 10px; } }

@media (min-width: 700px) {
  .page-container header img {
    width: 100%;
    margin: 0px; }
  .page-container header .signature {
    right: 42vw;
    bottom: 16vh;
    width: 54vw; } }

@media (min-width: 800px) {
  .page-container header {
    height: 50vh;
    overflow: hidden; }
    .page-container header .signature {
      left: 4vw;
      top: 3rem;
      width: 55vw; }
    .page-container header img {
      width: 115%;
      margin-left: 0px; }
    .page-container header .title {
      bottom: 2rem; }
  section.portfolio article .title {
    font-size: 1.8rem;
    bottom: 1.8rem; }
  section.portfolio article p.text a {
    display: inline; } }

@media (min-width: 900px) {
  .page-container header .signature {
    width: 47vw; }
  .page-container header .title {
    left: 2rem; }
  section.portfolio article .title {
    font-size: 1.8rem;
    bottom: 1.8rem; } }

@media (min-width: 1000px) {
  .page-container header {
    height: 60vh; }
    .page-container header .signature {
      right: 56vw;
      top: 1vh;
      width: 53vw; }
    .page-container header .title {
      width: 55%;
      bottom: 2rem;
      left: 3rem; }
    .page-container header img {
      width: 100%; }
  section.awards .award-description {
    max-width: 35vw; } }

@media (min-width: 1200px) {
  .page-container header .signature {
    width: 47vw; } }

@media (min-width: 1300px) {
  .page-container header {
    height: 68vh; }
    .page-container header .title {
      bottom: 6rem; }
      .page-container header .title h1 {
        font-size: 2.1rem; }
      .page-container header .title p {
        font-size: 1.2rem; } }

@media (min-width: 1500px) {
  .page-container header {
    height: 75vh; }
    .page-container header .title {
      bottom: 3rem; } }
