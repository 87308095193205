// Imports
@import url('https://fonts.googleapis.com/css?family=Raleway:200,400,700');
@import 'node_modules/hint.css/src/hint.scss';

// Variables
$chartBlue: #2b81be;
$dark: black;
$light: #e8e7f0;

// Styles
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

body {
    background-color: whitesmoke;
}

.page-container {
    width: 100%;
    border-top: 10px solid $dark;

    a {
        text-decoration: none;
        color: $chartBlue;
    }

    nav {
        position: fixed;
        top: -65px;
        width: 100%;
        height: 65px;
        padding-left: 15px;
        background-color: $dark;
        padding-top: 15px;
        overflow: hidden;
        font-weight: 200;
        letter-spacing: 5px;
        text-transform: uppercase;
        z-index: 5000;
        
        img {
            height: 40px;
            float: left;
        }
        
        .chapter {
            color: $light;
            font-size: 1em;
            margin-left: 20px;
            padding-left: 20px;
            line-height: 2em;
            border-left: 1px solid grey;

        }
        .email {
            font-size: 1.8rem;
            color: $light;
            float: right;
            margin-right: 15px;
        }
    }

    header {
        width: 100%;
        position: relative;

        .email {
            position: absolute;
            top: 20px;
            left: 20px;
            background-color: $dark;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            a {
                color: $light;
            }
        }
        
        .title {
            padding: 2rem;
            width: 100%;
            
            h1 {
                font-weight: 200;
                text-transform: uppercase;
                letter-spacing: 0.3rem;
                margin-bottom: 10px;
                font-size: 1.6rem;
            }
            
            p {
                line-height: 1.5rem;
            }
        }
                
        .signature {
            position: absolute;
            right: 27vw;
            top: 8rem;
            width: 68vw;
            z-index: 10;
            display: none;
        }
        .arrow {
            display: none;
        }
        // .bounce {
        //     -moz-animation: bounce 2s infinite;
        //     -webkit-animation: bounce 2s infinite;
        //     animation: bounce 2s infinite;
        // }
    }

    footer {
        background-color: $dark;
        color: $light;
        padding: 20px;
        text-align: center;
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: .9rem;
        
        .copyright {
            display: none;
        }
        .email {
            text-align: left;
            a {
                color: $light;
            }
        }
        .badges {
            text-align: right;
            .fab {
                font-size: 1.1rem;
                display: inline;
                margin-left: 15px;
                cursor: pointer;
                color: $light;
                background: none;
            }
        }
    }
}

section {
    padding: 50px 40px;
    margin: 0px auto 0px;
    width: 100%;
    max-width: 900px;
    
    .location {
        text-align: center;
        margin: 20px 0px 10px;
        font-weight: 400;
    }
    
    div.entry {
        margin-top: 20px;
    }
    
    .header {
        text-align: center;
        width: 100%;
        display: block;
        font-weight: 200;
        letter-spacing: 5px;
        font-size: 20px;
        margin-bottom: 50px;
    }
    
    .time {
        font-weight: 200;
        display: block;
        margin: 5px 0px 20px;
    }
    
    img {
        width: 100%;
    }
}
section.quotes {
    background: $dark;
    max-width: none;
    color: $light;
    p {
        max-width: 800px;
        margin: 0px auto;
        font-size: 1rem;
        line-height: 1.5rem;
        .fa-quote-left {
            margin-right: 20px;
        }
        .fa-quote-right {
            margin: 0px 20px;
        }
    }
    .attribution {
        width: 100%;
        text-align: right;    
    }
}
section.portfolio {
    
    article {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        justify-items: center;
        align-items: center;
        margin-bottom: 70px;

        &:last-child {
            margin-bottom: 0px;
        }
        
        .description {
            width: 100%;
            .text {
                line-height: 25px;
            }
            
            .title {
                display: block;
                margin: 20px 0px;
                text-align: center;
                width: 100%;
                font-size: 1.6rem;
                font-weight: 100;
            }
            .links {
                margin-top: 30px;
                display: block;
                a {
                    width: 150px;
                    margin: 0px auto;
                    border: 1px solid $chartBlue;
                    text-align: center;
                    display: block;
                    white-space: nowrap;
                    font-weight: 700;
                    margin-top: 15px;
                    cursor: pointer;
                    border-radius: 10px;
                    padding: 10px;
                    &:hover {
                        background-color: lighten($chartBlue, 50);
                    }
                }
                i {
                    margin-right: 10px;
                }
            }
            

            .badges {
                font-size: 1.3rem;
                margin-top: 30px;
                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    background-color: $chartBlue;
                    color: $light;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 0.9rem;
                    cursor: pointer;
                }
            }
        }
    }
}

section.experience {
    margin: 0px auto;
    
    .logo {
        width: 100%;
        text-align: center;
        margin: 30px 0px 40px;
        
        img {
            max-width: 15rem;
            width: 70%;
        }
    }
    
    article {
        margin-bottom: 50px;
        
        p {
            line-height: 27px;
        }

        .skills {
            margin-top: 20px;
        }
    }
}


section.skills {
    background-color: $light;
    color: $dark;
    padding-bottom: 30px;
    margin-bottom: 0px;
    max-width: none;
    
    .logos {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        margin: 30px auto 0px;
        max-width: 800px;
        
        .container {
            width: 100%;
            text-align: center;
            
            .logo {
                display: inline-block;
                width: 55px;
                height: 55px;
                line-height: 38px;
                padding: 10px;
                font-size: 30px;
                background-color: none;
                border-radius: 50%;
                margin-bottom: 5px;
            }
            
            .skill {
                display: block;
            }
        }
    }
}

section.development, section.learning {
    background-color: $light;
    color: $dark;
    padding-bottom: 30px;
    margin-bottom: 0px;
    max-width: none;

    .logos {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        margin: 30px auto 0px;
        max-width: 800px;

        .container {
            width: 100%;
            text-align: center;
            
            .logo {
                display: inline-block;
                width: 55px;
                height: 55px;
                line-height: 38px;
                padding: 10px;
                font-size: 30px;
                background-color: none;
                border-radius: 50%;
                margin-bottom: 5px;
            }
            
            .skill {
                display: block;
            }
        }
    }
}

section.learning {
    padding-bottom: 50px;
}

section.education {
    margin: 0px auto;
    max-width: 800px;
    
    .entry {
        margin-top: 30px;
        
        .time {
            margin-bottom: 7px;
        }
    }
}

section.awards {
    margin: 0px auto;
    max-width: 800px;
    padding-bottom: 0px;
    
    article {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 20px;

    }
    
    .award-description {
        margin-bottom: 50px;
        max-width: 90vw;
        text-align: center;
        margin: 0px auto 50px;
        
        a {
            font-size: inherit;
            font-weight: normal;
            margin: 0px;
            white-space: normal;
            display: inline;
        }
    }
    
    .entry.thumb {
        display: grid;
        grid-template-columns: 70px 1fr;
        grid-gap: 20px;
    }
    
    .entry.no-thumb {
        text-align: center;
    }
    
    .thumb-container{
        float: left;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin: 0px 10px 10px 0px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }
    
    .location {
        margin-top: 60px;
        margin-bottom: 5px;
    }
    
    .winner {
        font-style: italic;
        font-weight:  200;
    }
    
    a {
        display: block;
        white-space: nowrap;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;

    }
}

@media(min-width: 400px) {
    
    .page-container {

        header {
        
            .title {
                padding-top: 70px;
            }
        
            .signature {
                right: 33vw;
                bottom: 10rem;
                width: 62vw;
            }
        }
    }
}

@media(min-width: 600px) {

    .page-container {

        header {
        
            .title {
                position: absolute;
                bottom: 1rem;
                left: 2rem;
                width: 100%;
                padding: 0px;
            }
        
            .signature {
                right: 44vw;
                top: 2rem;
                width: 52vw;
            }
        }

        footer {
            grid-template-columns: repeat(3, 1fr);
            .copyright {
                display: inline;
            }
            .email {
                text-align: center;
            }
            .copyright {
                text-align: left;
            }
        }
    }
    
    section.awards .award-description {
        max-width: 60vw;
    }
    
    section.portfolio {
    
        article {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
    
            .description {
    
                .title {
                    text-align: left;
                    font-size: 1.5rem;
                    width: 100%;
                }

                .links a {
                    display: inline-block;
                    width: 100px;
                    margin-right: 10px;
                }
            }
        }
    }
}

@media(min-width: 700px) {
    
    .page-container {
   
        header {
        
            img {
                width: 100%;
                margin: 0px;
            }
        
            .signature {
                right: 42vw;
                bottom: 16vh;
                width: 54vw;
            }
        }
    }
}

@media(min-width: 800px) {

    .page-container {

        header {

            height: 50vh;
            overflow: hidden;

            .signature {
                left: 4vw;
                top: 3rem;
                width: 55vw;
            }
    
            img {
                width: 115%;
                margin-left: 0px;
            }

            .title {
                bottom: 2rem;
            }
        }
    }
    
    section.portfolio {
    
        article {
    
            .title {
                font-size: 1.8rem;
                bottom: 1.8rem;
            }
            p.text a{
                display: inline;

            }
        }
    }
}

@media(min-width: 900px) {
    
    .page-container {

        header {

            .signature {
                width: 47vw
            }

            .title {
                left: 2rem;
            }
        }
    }
    
    section.portfolio {
    
        article {
    
            .title {
                font-size: 1.8rem;
                bottom: 1.8rem;
            }
        }
    }
}

@media(min-width: 1000px) {
    
    .page-container {

        header {
            height: 60vh;

        // .arrow.bounce {
        //     display: inline ;
        //     width: 100%;
        //     text-align: center;
        //     position: absolute;
        //     bottom: 3rem;
        //     color: silver;
        //     font-size: 1.5rem;
        // }
    
            .signature {
                right: 56vw;
                top: 1vh;
                width: 53vw;
            }
    
            .title {
                width: 55%;
                bottom: 2rem;
                left: 3rem;
     
            }

            img {
                width: 100%;
            }
        }

    }
    
    section.awards .award-description {
        max-width: 35vw;
    }
}

@media(min-width: 1200px) {
    
    .page-container {

        header {
    
            .signature {
                width: 47vw;
            }
        }
    }
}

@media(min-width: 1300px) {

    .page-container {
    
        header {
            height: 68vh;

            .title {
                bottom: 6rem;
        
                h1 {
                    font-size: 2.1rem;
                }
        
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
    
}

@media(min-width: 1500px) {
    
    .page-container {
    
        header {
            height: 75vh;
    
            .title {
                bottom: 3rem;
            }
        }
    }
}